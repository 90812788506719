export default Object.freeze({
  'coco-bongo-playa-del-carmen': {
    name: 'Cocobongo Playa Del Carmen',
    caption: 'Show & Disco',
    logo:
      'https://imgix.cosmicjs.com/f816bad0-6a28-11ef-b5ae-a594bb4a8e67-logo-coco-bongo-cancun.png',
    secondaryColor: '#983957',
    color: '#6892b0',
    video: {
      title: 'Show Time!',
      youtube: 'true',
      content:
        '<p>Coco Bongo: Where the <strong>Party Never Stops</strong>. Join the excitement and let loose at Coco Bongo Playa Del Carmen.A must-do nightlife destination.</p>',
      id: 'R9EB5HkKqCI',
      videoUrl: 'https://www.youtube.com/watch?v=R9EB5HkKqCI',
    },
  },
  'ilios-playa-del-carmen': {
    name: 'Ilios Playa Del Carmen',
    caption: 'A Greek Fusion',
    logo:
      'https://imgix.cosmicjs.com/a6a32060-d5a6-11ee-9ce5-59949019255e-logo-ilios-restaurant-tulum.png',
    secondaryColor: '#ef905b',
    color: '#d0bc9e',
    video: {
      title: 'Greek Estiatorio',
      youtube: 'true',
      content:
        '<p>The oracle has said <strong>your fate</strong> is here, in Ilios\n' +
        '\n <br>Smash a plate and shout <strong>OPA!</strong> </p>',
      id: 'aJFDoKJwCdc',
      videoUrl: 'https://www.youtube.com/watch?v=aJFDoKJwCdc',
    },
  },
  'porfirios-restaurant-playa-del-carmen': {
    name: 'Ilios Playa Del Carmen',
    caption: 'A Greek Fusion',
    logo:
      'https://imgix.cosmicjs.com/a6a32060-d5a6-11ee-9ce5-59949019255e-logo-ilios-restaurant-tulum.png',
    secondaryColor: '#ef905b',
    color: '#d0bc9e',
    video: {
      title: 'Greek Estiatorio',
      youtube: 'true',
      content:
        '<p>The oracle has said <strong>your fate</strong> is here, in Ilios\n' +
        '\n <br>Smash a plate and shout <strong>OPA!</strong> </p>',
      id: 'aJFDoKJwCdc',
      videoUrl: 'https://www.youtube.com/watch?v=aJFDoKJwCdc',
    },
  },
  'la-vicenta-playa-del-carmen': {
    name: 'La Vicenta',
    caption: 'Sizzling steaks & Mexican flavors',
    logo:
      'https://imgix.cosmicjs.com/714d86c0-84e8-11ef-b5a0-93db72e2be98-club-pdc-la-visenta-logo-instagram.jpg',
    secondaryColor: '#ef905b',
    color: '#d0bc9e',
  },
  'on-heaven-playa-del-carmen': {
    name: 'On Heaven',
    caption: 'Melodic Side for Progressive Lovers',
    logo:
      'https://imgix.cosmicjs.com/c25c3bf0-97a4-11ef-b5a0-93db72e2be98-imagen_2024-10-31_132534804.png',
    secondaryColor: '#2054a7',
    color: '#525b98',
  },
  'speakeasy-playa-del-carmen': {
    name: 'SpeakEasy',
    caption: 'Electronic Club',
    logo:
      'https://imgix.cosmicjs.com/3a7e98b0-989c-11ef-b5a0-93db72e2be98-Speakeasy-7.jpg',
    secondaryColor: '#35241d',
    color: '#4f4242',
  },

});
